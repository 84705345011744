// @flow
import {
  ADD_CARD,
  UPDATE_CARD,
  EDIT_CARD,
  CLEAR_CARD,
  TOGGLE_CARD,
  EDIT_TIME,
  EDIT_TITLE,
  EDIT_ABOUT,
  EDIT_TAG,
  SET_VISIBILITY_FILTER,
  SHOW_ALL,
  SHOW_DELETED,
  SHOW_ACTIVE,
  CARD_FETCH_SUCCEEDED,
  CARD_FETCH_FAILED,
  CARD_FETCH_REQUESTED,
  CARD_FETCH_CANCEL,
  CARD_SAVE,
  CARD_SAVE_SUCCEEDED,
  CARD_SAVE_FAILED,
} from '../constants/action-types';

export const fetchSucceeded: CARD_FETCH_SUCCEEDED = (response) => ({
  type: CARD_FETCH_SUCCEEDED,
  response,
});

export const fetchFailed: CARD_FETCH_FAILED = (error) => ({
  type: CARD_FETCH_FAILED,
  error,
});

export const fetchRequested: CARD_FETCH_REQUESTED = () => ({
  type: CARD_FETCH_REQUESTED,
});

export const fetchCancel: CARD_FETCH_CANCEL = () => ({
  type: CARD_FETCH_CANCEL,
});

export const saveCards: CARD_SAVE = () => ({
  type: CARD_SAVE,
});

export const saveSucceeded: CARD_SAVE_SUCCEEDED = (lastUpdate) => ({
  type: CARD_SAVE_SUCCEEDED,
  lastUpdate,
});

export const saveFailed: CARD_SAVE_FAILED = (error) => ({
  type: CARD_SAVE_FAILED,
  error,
});

let nextCardId = 0;
export const addCard: ADD_CARD = (props) => {
  nextCardId += 1;
  // console.log(props);
  return {
    type: ADD_CARD,
    id: nextCardId,
    // edit: props,
    time: props.time || '23 Oct 018',
    title: props.title || '柯P',
    description: props.description || '台北市長柯文哲在PTT上別稱',
    tags: props.tags || ['人物', '政治', '台北'],
  };
};

export const updateCard: UPDATE_CARD = (card) => ({
  type: UPDATE_CARD,
  id: card.id,
  time: card.time,
  title: card.title,
  description: card.description,
  tags: card.tags,
});

export const editCard: EDIT_CARD = (edit) => ({
  type: EDIT_CARD,
  edit,
});

export const clearCard: CLEAR_CARD = () => ({
  type: CLEAR_CARD,
});

export const toggleCard: TOGGLE_CARD = (id) => ({
  type: TOGGLE_CARD,
  id,
});

export const editTime: EDIT_TIME = (time) => ({
  type: EDIT_TIME,
  time,
});

export const editTitle: EDIT_TITLE = (title) => ({
  type: EDIT_TITLE,
  title,
});

export const editAbout: EDIT_ABOUT = (about) => ({
  type: EDIT_ABOUT,
  about,
});

export const editTag: EDIT_TAG = (tag) => ({
  type: EDIT_TAG,
  tag,
});

export const setVisibilityFilter: SET_VISIBILITY_FILTER = (filter) => ({
  type: SET_VISIBILITY_FILTER,
  filter,
});

export const VisibilityFilters = {
  SHOW_ALL,
  SHOW_DELETED,
  SHOW_ACTIVE,
};
