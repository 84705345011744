// @flow
export const ADD_CARD = 'ADD_CARD';
export const UPDATE_CARD = 'UPDATE_CARD';
export const EDIT_CARD = 'EDIT_CARD';
export const DELETE_CARD = 'DELETE_CARD';
export const CLEAR_CARD = 'CLEAR_CARD';
export const TOGGLE_CARD = 'TOGGLE_CARD';
export const EDIT_TIME = 'EDIT_TIME';
export const EDIT_TITLE = 'EDIT_TITLE';
export const EDIT_ABOUT = 'EDIT_ABOUT';
export const EDIT_TAG = 'EDIT_TAG';
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const SHOW_ALL = 'SHOW_ALL';
export const SHOW_DELETED = 'SHOW_DELETED';
export const SHOW_ACTIVE = 'SHOW_ACTIVE';
export const CARD_FETCH_SUCCEEDED = 'CARD_FETCH_SUCCEEDED';
export const CARD_FETCH_FAILED = 'CARD_FETCH_FAILED';
export const CARD_FETCH_REQUESTED = 'CARD_FETCH_REQUESTED';
export const CARD_FETCH_CANCEL = 'CARD_FETCH_CANCEL';
export const CARD_SAVE = 'CARD_SAVE';
export const CARD_SAVE_SUCCEEDED = 'CARD_SAVE_SUCCEEDED';
export const CARD_SAVE_FAILED = 'CARD_SAVE_FAILED';
